<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import RecentActivityOrder from "@/components/recent-activity-order";
import useHttp from '@/comp-functions/useHttp'

import { ref } from '@vue/composition-api'


const SCHEDULE = {
  originport: {
  prt_initial:'',
},
destinationport: {
  prt_initial:'',
}
}

export default {
  props: {
    ordId: String
  },
  page: {
    title: "Tracking",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    RecentActivityOrder,Layout, PageHeader
  },
  data() {
    return {
      title: "Tracking",
      items: [],
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true
    };
  },
  setup(props){
    const { $get } = useHttp()

    const noOrder = ref()
    const idOrder = ref(props.ordId)
    const selectedSchedule = ref({...SCHEDULE})


    // const selectedSchedule = ref({....SCHEDULE})

    // const form = ref({
    //   ordScheduleId: 1//props.schedule_id,
     
    // })


    const getOrderNo = async () => { 
      const {data} = await $get({ url: 'master/search_order/' +  noOrder.value})
      idOrder.value = data.ord_id
      selectedSchedule.value = data.schedule
    }

    //  const getSchedule = async () => {
    //   const {data} = await $get({ url: 'master/schedule/' + form.value.ordScheduleId})
    //   selectedSchedule.value = data
    // }

    //  onMounted(() => {
    //  getSchedule()

    // })

    return{
      noOrder,
      getOrderNo,
      idOrder,
      // getSchedule,
      selectedSchedule
    }

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order/SMU No."
                    label-for="order_no"
                  >
                    <b-form-input v-model="noOrder" ></b-form-input>
                    <b-button variant="primary" @click.prevent="getOrderNo">
                      Search
                      </b-button>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Route"
                    label-for="vendflight_vendorid"
                  >
                    <div class="pt-2">{{selectedSchedule.originport.prt_initial}} - {{selectedSchedule.destinationport.prt_initial}}</div>
                  </b-form-group>

                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      
      <div class="col-lg-12">
        <RecentActivityOrder :idOrder="idOrder"/>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>